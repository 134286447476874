import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {ButtonPriority} from 'wix-ui-tpa';
import {classes} from './Footer.st.css';
import {CheckoutButton} from '../../../../../common/components/CheckoutButton/CheckoutButton';
import {CartButton} from '../CartButton/CartButton';
import {SecureCheckout} from '../../../../../common/components/SecureCheckout/SecureCheckout';
import {Violations} from '../../../../../common/components/Violations/Violations';
import settingsParams from '../../../settingsParams';
import stylesParams from '../../../stylesParams';
import {useSettings, useStyles} from '@wix/tpa-settings/react';
import {Divider, Text} from 'wix-ui-tpa/cssVars';

export enum FooterDataHooks {
  root = 'Footer.root',
  subtotalContainer = 'Footer.subtotalContainer',
  subtotalLabel = 'Footer.subtotalLabel',
  subtotalValue = 'Footer.subtotalValue',
  subtotalDisclaimer = 'Footer.subtotalDisclaimer',
  currencyConverterDisclaimer = 'Footer.currencyConverterDisclaimer',
}

export const Footer = () => {
  const {t} = useTranslation();
  const {cart, shouldRenderEmptyState} = useControllerProps().cartStore;
  const {orderStore} = useControllerProps();
  const {isCheckoutButtonDisabled} = useControllerProps().navigationStore;
  const {get: getSettings} = useSettings();
  const {get: getStyles} = useStyles();
  if (shouldRenderEmptyState) {
    return false;
  }
  const {isMainCurrency} = orderStore;
  const shouldShowTotalsDisclaimer = getStyles(stylesParams.CART_SUMMARY_DISCLAIMER_VISIBILITY);
  const shouldShowSecureCheckout = getStyles(stylesParams.CART_SUMMARY_SECURE_BADGE_VISIBILITY);
  const shouldGoToCartButton = getStyles(stylesParams.CART_ACTION_BUTTONS_GO_TO_CART_VISIBILITY);
  const shouldShowCheckoutButton = getStyles(stylesParams.CART_ACTION_BUTTONS_CHECKOUT_VISIBILITY);

  return (
    <section className={classes.root} data-hook={FooterDataHooks.root}>
      <Divider className={classes.divider} />
      <div className={classes.container}>
        <div
          className={classes.subtotalRow}
          aria-live="polite"
          role="status"
          data-hook={FooterDataHooks.subtotalContainer}>
          <Text tagName={'h2'} data-hook={FooterDataHooks.subtotalLabel} className={classes.subtotalLabel}>
            {t('sideCart.subtotal.label')}
          </Text>

          <Text data-hook={FooterDataHooks.subtotalValue} className={classes.subtotalValue} data-wix-sub-totals-value>
            {cart.convertedTotals.formattedItemsTotal}
          </Text>
        </div>
        <div className={classes.disclaimers}>
          {!isMainCurrency && (
            <Text data-hook={FooterDataHooks.currencyConverterDisclaimer} className={classes.disclaimerText}>
              {t('cart.currency_converter_disclaimer', {mainCurrency: cart.currencyFormat.code})}
            </Text>
          )}
          {shouldShowTotalsDisclaimer && (
            <Text data-hook={FooterDataHooks.subtotalDisclaimer} className={classes.disclaimerText}>
              {t('sideCart.subtotal.disclaimer')}
            </Text>
          )}
        </div>
        <Violations />
        <div className={classes.buttons}>
          {shouldShowCheckoutButton && (
            <CheckoutButton
              priority={ButtonPriority.primary}
              disabled={isCheckoutButtonDisabled}
              text={getSettings(settingsParams.CART_BUTTON_TEXT) as string}
            />
          )}
          {shouldGoToCartButton && <CartButton />}
        </div>
        {shouldShowSecureCheckout && <SecureCheckout text={t('sideCart.secureCheckout.badge')} />}
      </div>
    </section>
  );
};
